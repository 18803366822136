import * as React from "react";
import Plx from "react-plx";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const isBrowser = typeof window !== "undefined";

const Nft = (props) => {
  const nftRef = React.useRef(null);
  const boxRef = React.useRef(null);
  const novaRef = React.useRef(null);
  const nova1Ref = React.useRef(null);

  const [playing, setPlaying] = React.useState(false);
  const [muted, setMuted] = React.useState(true);
  const [isVisible, setIsVisible] = React.useState(false);
  const [slideIndex, setSlideIndex] = React.useState(0);

  const toggleMuted = () => {
    setMuted(!muted);
  };

  const observer = new IntersectionObserver(([entry]) =>
    setIsVisible(entry.isIntersecting)
  );

  const updateScrollPosition = () => {
    if (nftRef.current) {
      if (!isVisible) {
        novaRef.current.pause();
        nova1Ref.current.pause();
        setPlaying(false);
      } else {
        if (!playing) {
          novaRef.current.play();
          nova1Ref.current.play();
        }
        setPlaying(true);
      }
    }
  };

  React.useEffect(() => {
    if (isBrowser) window.addEventListener("scroll", updateScrollPosition);
    if (nftRef.current) observer.observe(nftRef.current);
    props.total(nftRef.current ? nftRef.current.clientHeight : 0);
    return () => {
      if (isBrowser) window.removeEventListener("scroll", updateScrollPosition);
    };
  });

  const properties = {
    autoplay: false,
    indicators: false,
    transitionDuration: 0,
    onChange: (previous, next) => {
      setSlideIndex(next);
    },
    prevArrow: (
      <div className="prev-arrow">
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path
            d="M34 25L61.5 49.5L38 74"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      </div>
    ),
    nextArrow: (
      <div className="next-arrow">
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
          <path
            d="M38 25L61.5 49.5L38 74"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      </div>
    ),
  };

  return (
    <div ref={nftRef} className="nft">
      <div className="copy-left">
        <div className="copy-absolute">
          <h2>
            3D
            <span className="hide-desktop">
              <br />
              FULLY
              <br />
              ANIMATED
            </span>
            <br />
            NFTs
          </h2>
          <p style={{ opacity: slideIndex == 0 ? 1 : 0 }}>
            <strong>NOVA 5</strong>
            <br />
            THE ONE
            <br />
            Opal Rarity
            <br />
            Limited Edition
          </p>
          <p style={{ opacity: slideIndex == 1 ? 1 : 0 }}>
            <strong>NOVA 4</strong>
            <br />
            Web3 Community
            <br />
            Opal Rarity
            <br />
            Limited Edition 001/333
          </p>
        </div>
      </div>
      <div className="vid">
        <a
          ref={boxRef}
          className={"boxContainer" + (muted ? "" : " boxContainerActive")}
          onClick={toggleMuted}
        >
          <div className="box box1"></div>
          <div className="box box2"></div>
          <div className="box box3"></div>
          <div className="box box4"></div>
          <div className="box box5"></div>
        </a>
        <Fade {...properties}>
          <video
            ref={novaRef}
            playsInline
            loop
            muted={slideIndex != 0 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/drop001/NOVA_THE_ONE.mp4"
              type="video/mp4"
            />
          </video>
          <video
            ref={nova1Ref}
            playsInline
            loop
            muted={slideIndex != 1 ? true : muted}
          >
            <source
              src="https://cdn.cultandrain.com/NOVA4_001_opaL_v2_.mp4"
              type="video/mp4"
            />
          </video>
        </Fade>
      </div>
      <div className="copy-right hide-mobile">
        <div className="copy-absolute">
          <h2>
            FULLY
            <br />
            ANIMATED
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Nft;
