import React, { useEffect } from "react";
import headRight from "../images/2.0/head-right.png";
import headLeft from "../images/2.0/head-left.png";
import colBg from "../images/2.0/collabs-bg.jpg";
import collabsDeez from "../images/drop001/collabs-icon-deez-square.png";
import collabsWonderPals from "../images/drop001/collabs-icon-wonderpals.png";
import collabsRobotos from "../images/drop001/collabs-icon-robotos.png";
import collabsTHP from "../images/drop001/collabs-icon-thp.png";
import collabsHPPRS from "../images/2.0/thumbnail_HPPRS PROFILE.png";
import Plx from "react-plx/lib/Plx";
const CollebsWeb = (props) => {
  const collebsRef = React.useRef(null);

  React.useEffect(() => {
    props.total(collebsRef.current ? collebsRef.current.clientHeight : 0);
    return () => {};
  });

  const parallaxData = [
    {
      start: props.start,
      end:
        props.start +
        (collebsRef.current ? collebsRef.current.clientHeight : 0),

      properties: [
        {
          startValue: 0,
          endValue: -8,
          property: "translateX",
          unit: "vw",
        },
      ],
    },
  ];
  const parallaxData1 = [
    {
      start: props.start,
      end:
        props.start +
        (collebsRef.current ? collebsRef.current.clientHeight : 0),
      properties: [
        {
          startValue: 0,
          endValue: 8,
          property: "translateX",
          unit: "vw",
        },
      ],
    },
  ];

  return (
    <div ref={collebsRef} class="collabs-container">
      <div class="collabs-copy">
        <div class="collabs-head">
          <h3>COLLABS</h3>
          <h5>WEB3 COMMUNITIES</h5>
          <p>
            Web3 IS community, and we are a SQUAD. We have once again teamed up
            with some of the best NFT projects and pioneers within the space.
            CULT&RAIN is honored to showcase each of their unique patch artwork
            designs on our limited-edition “Community Varsity Jacket.”
          </p>
        </div>

        <div class="collabs-body">
          {/* <div class="collabs-half collabs-left">
            <img src={headLeft} alt="" />
          </div> */}
          <Plx
            className="collabs-half collabs-left"
            parallaxData={parallaxData}
          >
            <img src={headLeft} alt="" />
          </Plx>

          <div class="collabs-content">
            <div class="collabs-point">
              <div class="collabs-dot">
                <img src={collabsDeez} alt="DEEZE" />
              </div>
              <div class="collabs-point-text">
                <h2>DEEZE</h2>
                <p>
                  @DeezeFi is an active member in the NFT space and director of
                  “vibes” at @fractional_art and @neon_dao. @DeezeFi regularly
                  holds enlightening Twitter Space discussions with topics such
                  as NFT Pricing and Fractional Fridays. @DeezeFi is also a
                  specialty coffee fan which is another reason we give his
                  twitter feed two thumbs up!
                </p>
              </div>
            </div>

            <div class="collabs-point">
              <div class="collabs-dot">
                <img src={collabsWonderPals} alt="WONDERPALS" />
              </div>
              <div class="collabs-point-text-alt">
                <h2>WONDERPALS</h2>
                <p>
                  WonderPals is a collection of 10,000 delightfully cute pals
                  living on the Ethereum blockchain, by artist & founder Mina.
                  WonderPals' vision is to create a delightful family friendly
                  brand that can be enjoyed by everyone across both the physical
                  and digital worlds. The world is ready for a universally loved
                  character that will bring out the values of kindness and bring
                  joy to the world through community, creativity, and fun art!
                </p>
              </div>
            </div>

            <div class="collabs-point">
              <div class="collabs-dot">
                <img src={collabsRobotos} alt="ROBOTOS" />
              </div>
              <div class="collabs-point-text">
                <h2>ROBOTOS</h2>
                <p>
                  Robotos is a collection of 9,999 droid characters designed by
                  Pablo Stanley and minted as NFTs. They are constructed from
                  various metal outfits, tin faces, digital accessories, top
                  pieces, faces, backpacks, arms, and colors. The Robotos
                  Genesis collection was an incredible success selling out in 20
                  minutes and, today, has 5,500 collectors around the world. In
                  a bold move, Robotos has just secured a partnership with TIME
                  Studios, an Emmy Award® and winning TV studio, to create a
                  Robotos-inspired animated show.
                </p>
              </div>
            </div>

            <div class="collabs-point">
              <div class="collabs-dot">
                <img src={collabsTHP} alt="THE HEART PROJECT" />
              </div>
              <div class="collabs-point-text-alt">
                <h2>THE HEART PROJECT</h2>
                <p>
                  The Heart Project is a wildly popular community-run Web3
                  project founded by Aidan Cullen and Stefan Meier, which
                  enables passionate lovers of creativity to shape the art we
                  interact with. The Heart Project Creative Studio is designed
                  to produce media with thousands of contributors on every
                  project and grants ownership of their shared creations. Aidan
                  Cullen is a renowned photographer and filmmaker based in Los
                  Angeles & NYC. His multi-disciplinary art navigates between
                  music, fashion, documentary and narrative worlds. Stefan Meier
                  is a highly respected artist based in Los Angeles. He works in
                  painting, drawing, collage, and textile design. His artistic
                  expression often delves into humankind’s relationship with
                  nature.
                </p>
              </div>
            </div>

            <div class="collabs-point">
              <div class="collabs-dot">
                <img src={collabsHPPRS} alt="HPPRS" />
              </div>
              <div class="collabs-point-text">
                <h2>HPPRS</h2>
                <p>
                  SHAKKABLOOD started his journey as a digital 3D artist doing
                  album artwork for notable EDM DJs/producers as well as logo
                  design, branding and creating artwork for musicians.
                  SHAKKABLOOD made an effective entry across the world of NFTs
                  with his HPPRS collection & has worked hard to establish
                  success for himself across the industry. SHAKKABLOOD has found
                  himself in the perfect spot within the community with his
                  unique and extremely impressive digital art.
                </p>
              </div>
            </div>
          </div>

          <Plx
            className="collabs-half collabs-right"
            parallaxData={parallaxData1}
          >
            <img src={headRight} alt="" />
          </Plx>
        </div>
        <div class="collabs-content-mobile">
          <div class="collabs-collab-mobile">
            <h2>
              <img src={collabsDeez} alt="DEEZE" /> DEEZE
            </h2>
            <p>
              @DeezeFi is an active member in the NFT space and director of
              “vibes” at @fractional_art and @neon_dao. @DeezeFi regularly holds
              enlightening Twitter Space discussions with topics such as NFT
              Pricing and Fractional Fridays. @DeezeFi is also a specialty
              coffee fan which is another reason we give his twitter feed two
              thumbs up!
            </p>
          </div>

          <div class="collabs-collab-mobile">
            <h2>
              <img src={collabsWonderPals} alt="WONDERPALS" />
              WONDERPALS
            </h2>
            <p>
              WonderPals is a collection of 10,000 delightfully cute pals living
              on the Ethereum blockchain, by artist & founder Mina. WonderPals'
              vision is to create a delightful family friendly brand that can be
              enjoyed by everyone across both the physical and digital worlds.
              The world is ready for a universally loved character that will
              bring out the values of kindness and bring joy to the world
              through community, creativity, and fun art!
            </p>
          </div>

          <div class="collabs-collab-mobile">
            <h2>
              <img src={collabsRobotos} alt="ROBOTOS" />
              ROBOTOS
            </h2>
            <p>
              Robotos is a collection of 9,999 droid characters designed by
              Pablo Stanley and minted as NFTs. They are constructed from
              various metal outfits, tin faces, digital accessories, top pieces,
              faces, backpacks, arms, and colors. The Robotos Genesis collection
              was an incredible success selling out in 20 minutes and, today,
              has 5,500 collectors around the world. In a bold move, Robotos has
              just secured a partnership with TIME Studios, an Emmy Award® and
              winning TV studio, to create a Robotos-inspired animated show.
            </p>
          </div>

          <div class="collabs-collab-mobile">
            <h2>
              <img src={collabsTHP} alt="THE HEART PROJECT" />
              THE HEART PROJECT
            </h2>
            <p>
              The Heart Project is a wildly popular community-run Web3 project
              founded by Aidan Cullen and Stefan Meier, which enables passionate
              lovers of creativity to shape the art we interact with. The Heart
              Project Creative Studio is designed to produce media with
              thousands of contributors on every project and grants ownership of
              their shared creations. Aidan Cullen is a renowned photographer
              and filmmaker based in Los Angeles & NYC. His multi-disciplinary
              art navigates between music, fashion, documentary and narrative
              worlds. Stefan Meier is a highly respected artist based in Los
              Angeles. He works in painting, drawing, collage, and textile
              design. His artistic expression often delves into humankind’s
              relationship with nature.
            </p>
          </div>

          <div class="collabs-collab-mobile">
            <h2>
              <img src={collabsHPPRS} alt="HPPRS" />
              HPPRS
            </h2>
            <p>
              SHAKKABLOOD started his journey as a digital 3D artist doing album
              artwork for notable EDM DJs/producers as well as logo design,
              branding and creating artwork for musicians. SHAKKABLOOD made an
              effective entry across the world of NFTs with his HPPRS collection
              & has worked hard to establish success for himself across the
              industry. SHAKKABLOOD has found himself in the perfect spot within
              the community with his unique and extremely impressive digital
              art.
            </p>
          </div>
        </div>
      </div>

      <div class="collabs-bg">
        <img src={colBg} alt="" />
      </div>
    </div>
  );
};

export default CollebsWeb;
