import React from "react";
import dropImgBg from "../images/2.0/drop001-presale-bg.jpg";
import Plx from "react-plx";
const DropContainer = (props) => {
  const dropRef = React.useRef(null);

  let h3a = [
    {
      start: props.start * 0.5,
      end:
        props.start +
        (dropRef.current ? dropRef.current.clientHeight * 0.6 : 0),
      properties: [
        {
          startValue: 100,
          endValue: 0,
          property: "translateX",
          unit: "vw",
        },
      ],
    },
  ];
  let h3b = [
    {
      start: props.start * 0.5,
      end:
        props.start +
        (dropRef.current ? dropRef.current.clientHeight * 0.6 : 0),
      properties: [
        {
          startValue: -100,
          endValue: 0,
          property: "translateX",
          unit: "vw",
        },
      ],
    },
  ];

  React.useEffect(() => {
    props.total(dropRef.current ? dropRef.current.clientHeight : 0);
    return () => {};
  });
  return (
    <div class="drop-container" ref={dropRef}>
      <div class="drop-body">
        <Plx tagName="h3" parallaxData={h3b}>
          DROP
        </Plx>
        <Plx tagName="h3" parallaxData={h3a}>
          001
        </Plx>
        <p style={{ marginTop: "4em" }}>
          Our Drop 001 collection is made for crypto enthusiasts who have an
          affinity for stunning 3D NFT artwork and cutting-edge designs;
          bridging the gap between digital and physical fashion.
        </p>
        <p>
          <strong>228 3D animated NFTs</strong> matched with <strong>228 trend setting physical varsity
          jackets</strong> will thrill discerning collectors worldwide and make a bold
          entry into the Web3 fashion space.
        </p>
        <p>
          <strong>Limited Drop 001 Edition</strong>
          <br />
          1 radical outerwear design
          <br />5 unique colorways
          <br />2 phygital jackets
          <br />5 metaverse wearables
          <br />Physical jacket redemption window is now CLOSED
        </p>
        <p>
          <strong>Highest Quality</strong>
          <br />
          Physical luxury, sourcing the finest “MADE IN ITALY” materials and
          fabrication through our coveted supply chains.
        </p>
      </div>
      <div class="drop-bg">
        <img src={dropImgBg} alt="" />
      </div>
    </div>
  );
};

export default DropContainer;
