import * as React from "react";
import { Helmet } from "react-helmet";
import "../styles/landing.css";
import Loader from "../components/loader.js";
import Icons from "../components/icons.js";
import Intro from "../components/intro.js";
import Teaser from "../components/secondaryTeaser.js";
import Discover from "../components/discover.js";
import Nft from "../components/drop001/nft.js";
import CultureRain from "../components/culturerain.js";
import RoadMapText from "../components/roadmaptext.js";
import Team from "../components/team.js";
import Join from "../components/join.js";
import Footer from "../components/footer.js";
import DropContainer from "../components/dropContainer";
import PreSaleAccess from "../components/preSaleAccess";
import CollebsWeb from "../components/collebsWeb";
import PlanContainer from "../components/planContainer";
import JacketComponent from "../components/jacketComponent";
import JacketComponentLower from "../components/jacketComponentLower";
import Shoes from "../components/shoes.js";
import HomeSVG from "../images/2.0/home.svg";

var Scroll = require("react-scroll");
var scroll = Scroll.animateScroll;

const isBrowser = typeof window !== "undefined";

// markup
const Drop001Page = () => {

  const endRef = React.useRef(null);

  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
  const [isVideoSent, setIsVideoSent] = React.useState(false);
  const [teaserSrc, setTeaserSrc] = React.useState(null);
  const [orientation, setOrientation] = React.useState("landscape");

  const onLoadedData = () => {
    //setIsVideoLoaded(true);
  };

  const [width, setWidth] = React.useState(isBrowser ? window.innerWidth : 0);
  const [height, setHeight] = React.useState(
    isBrowser ? window.innerHeight : 0
  );

  const [footerVisible, setFooterVisible] = React.useState(false);
  const [mintVisible, setMintVisible] = React.useState(true);

  const [introHeight, setIntroHeight] = React.useState(0);
  const [collebsWebHeight, setCollebsWebHeight] = React.useState(0);
  const [jacketHeight, setJacketHeight] = React.useState(0);
  const [jacketHeightL, setJacketHeightL] = React.useState(0);

  const [dropHeight, setDropHeigh] = React.useState(0);
  const [presaleAccessHeight, setPresaleAccessHeight] = React.useState(0);

  const [rockHeight, setRockHeight] = React.useState(0);
  const [shoesHeight, setShoesHeight] = React.useState(0);
  const [hexHeight, setHexHeight] = React.useState(0);
  const [presaleHeight, setPresaleHeight] = React.useState(0);
  const [discoverHeight, setDiscoverHeight] = React.useState(0);
  const [nftHeight, setNftHeight] = React.useState(0);

  const [roadmapHeight, setRoadmapHeight] = React.useState(0);
  const [futureHeight, setFutureHeight] = React.useState(0);
  const [generationHeight, setGenerationHeight] = React.useState(0);
  const [totalHeight, setTotalHeight] = React.useState(0);
  const [cultureHeight, setCultureHeight] = React.useState(0);
  const [joinHeight, setJoinHeight] = React.useState(0);
  const [collabHeight, setCollabHeight] = React.useState(0);
  const [lettersHeight, setLettersHeight] = React.useState(0);

  const [muted, setMuted] = React.useState(true);
  const [active, setActive] = React.useState(true);
  const [showToggle, setShowToggle] = React.useState(false);

  const boxRef = React.useRef(null);

  const updateWidthAndHeight = () => {
    setWidth(isBrowser ? window.innerWidth : 0);
    setHeight(isBrowser ? window.innerHeight : 0);
  };

  const toggleMuted = () => {
    setMuted(!muted);
  };

  const updateScrollPosition = () => {
    if (!boxRef.current) return;
    if ((isBrowser ? window.scrollY : 800) > height) {
      boxRef.current.style.display = "none";
    } else {
      boxRef.current.style.display = "flex";
    }
  };

  const setScreenOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrientation("portrait");
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      setOrientation("landscape");
    }
  };

  const onLoadEnd = () => {
    setIsVideoLoaded(true);
  };

  React.useEffect(() => {

    setTeaserSrc("https://cdn.cultandrain.com/DROP001-TEASER-NO-COMING.mp4");

    setScreenOrientation();
    updateWidthAndHeight();
    if (isBrowser) window.addEventListener("resize", updateWidthAndHeight);
    if (isBrowser) window.addEventListener("scroll", updateScrollPosition);
    if (isBrowser)
      window.addEventListener("orientationchange", setScreenOrientation);
    return () => {
      if (isBrowser) window.removeEventListener("resize", updateWidthAndHeight);
      if (isBrowser) window.removeEventListener("scroll", updateScrollPosition);
      if (isBrowser)
        window.removeEventListener("orientationchange", setScreenOrientation);
    };
  });
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DROP 001 COLLECTION | CULT&RAIN&trade;</title>
        <link rel="canonical" href="https://www.cultandrain.com/drop-001/" />
        <meta
          name="description"
          content="The first luxury fashion brand born from the crypto universe merging NFTs with exclusive, physical redeemables. Join our Web3 revolution."
        />
      </Helmet>
      {isBrowser && (
        <div className="drop001">
          <div className="wrapper">
            {!isVideoLoaded && <Loader />}
            {isVideoLoaded && (
              <div>
                <a href="/" className="homebtn"><img src={HomeSVG} /></a>
                <Icons mintVisible={true} page="drop001" />
                <a
                  ref={boxRef}
                  className={
                    "boxContainer" + (muted ? "" : " boxContainerActive")
                  }
                  onClick={toggleMuted}
                >
                  <div className="box box1"></div>
                  <div className="box box2"></div>
                  <div className="box box3"></div>
                  <div className="box box4"></div>
                  <div className="box box5"></div>
                </a>
              </div>
            )}
            <div
              className="page"
            >
              <Teaser
                height={height}
                muted={muted}
                teaserSrc={teaserSrc}
                landing={true}
                onLoadEnd={onLoadEnd}
              />
              <div ref={endRef} />
              {isVideoLoaded && (
                <div>
                  <DropContainer
                    start={introHeight}
                    height={height}
                    total={setDropHeigh}
                    orientation={orientation}
                  />
                  <JacketComponent
                    start={introHeight + dropHeight}
                    height={height}
                    total={setJacketHeight}
                    orientation={orientation}
                  />
                  <Shoes
                    start={
                      introHeight +
                      dropHeight +
                      jacketHeight -
                      400
                    }
                    height={height}
                    total={setShoesHeight}
                    orientation={orientation}
                  />
                  {/* <JacketComponentLower
                    start={introHeight + dropHeight}
                    height={height}
                    total={setJacketHeightL}
                    orientation={orientation}
                  /> */}
                  <PreSaleAccess
                    start={
                      introHeight +
                      dropHeight +
                      jacketHeight +
                      shoesHeight
                    }
                    height={height}
                    total={setPresaleAccessHeight}
                    orientation={orientation}
                  />

                  <Discover
                    start={
                      introHeight +
                      dropHeight +
                      presaleAccessHeight +
                      jacketHeight +
                      shoesHeight
                    }
                    height={height}
                    total={setDiscoverHeight}
                    orientation={orientation}
                  />
                  <Nft
                    start={
                      introHeight +
                      dropHeight +
                      presaleAccessHeight +
                      jacketHeight +
                      discoverHeight +
                      shoesHeight
                    }
                    height={height}
                    total={setNftHeight}
                    orientation={orientation}
                  />

                  <CultureRain
                    start={
                      introHeight +
                      dropHeight +
                      presaleAccessHeight +
                      jacketHeight +
                      discoverHeight +
                      nftHeight +
                      shoesHeight
                    }
                    height={height}
                    total={setCultureHeight}
                    orientation={orientation}
                  />

                  <CollebsWeb
                    start={
                      introHeight +
                      dropHeight +
                      presaleAccessHeight +
                      discoverHeight +
                      nftHeight +
                      jacketHeight +
                      shoesHeight +
                      cultureHeight
                    }
                    height={height}
                    total={setCollebsWebHeight}
                    orientation={orientation}
                  />
                  <Join
                    start={
                      introHeight +
                      presaleAccessHeight +
                      collabHeight +
                      jacketHeight +
                      nftHeight +
                      cultureHeight +
                      futureHeight +
                      shoesHeight
                    }
                    height={height}
                    total={setJoinHeight}
                  />
                  <Footer
                    start={
                      introHeight +
                      rockHeight +
                      shoesHeight +
                      roadmapHeight +
                      hexHeight +
                      presaleHeight +
                      collabHeight +
                      discoverHeight +
                      nftHeight +
                      cultureHeight +
                      lettersHeight +
                      height +
                      futureHeight +
                      generationHeight +
                      totalHeight +
                      joinHeight +
                      dropHeight +
                      presaleAccessHeight +
                      jacketHeight +
                      shoesHeight
                    }
                    height={height}
                    setFooterVisible={setFooterVisible}
                    page="drop001"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <form
        style={{ display: "none" }}
        name="Join"
        method="POST"
        data-netlify="true"
      >
        <input type="email" name="email" />
      </form>
    </main>
  );
};

export default Drop001Page;
