import { Link } from "gatsby";
import React from "react";
const PreSaleAccess = (props) => {
  const preSaleaccessRef = React.useRef(null);

  React.useEffect(() => {
    props.total(
      preSaleaccessRef.current ? preSaleaccessRef.current.clientHeight : 0
    );
    return () => {};
  });
  return (
    <div ref={preSaleaccessRef} class="presale">
      <div class="presale_copy">
        <h2>UTILITIES</h2>
        <h5>WE VALUE COMMUNITY FIRST</h5>
        <p style={{marginTop:'0px'}}>Every NFT minted includes unique rarity attributes, select governance<br />rights, and one of a kind redeemables with VIP access.</p>
        <h3>INTRODUCING THE ULTIMATE<br /><strong>RARITY SCALE</strong></h3>
        <p style={{marginTop:'0px'}}>To learn more about our DROP 001 UTILITIES please click below.</p>
        <p><a href="/cultr-pass-drop001">LEARN MORE</a></p>
      </div>

      <video
        playsInline
        loop
        muted={true}
        autoPlay
        style={{width:'100%'}}
      >
        <source
          src="https://cdn.cultandrain.com/chamber loop 6 sec_.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default PreSaleAccess;
