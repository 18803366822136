import React, { useState } from "react";
const isBrowser = typeof window !== "undefined";

const JacketComponent = (props) => {
  const collebsRef = React.useRef(null);
  const jacketRef = React.useRef(null);

  const [animation, setAnimation] = useState(false);
  const updateScrollPosition = () => {
    if (
      collebsRef.current.offsetTop - window.scrollY <=
      (props.orientation === "portrait" ? 200 : -200)
    ) {
      setAnimation(true);
    } else {
      setAnimation(false);
    }
  };
  React.useEffect(() => {
    props.total(collebsRef.current ? collebsRef.current.clientHeight : 0);
    if (isBrowser) window.addEventListener("scroll", updateScrollPosition);

    return () => {
      if (isBrowser) window.removeEventListener("scroll", updateScrollPosition);
    };
  });

  const parallaxData = [
    {
      start: props.start + props.height * 1.5,
      end: props.start + props.height * 2.5 + props.height / 4,

      properties: [
        {
          startValue: -1200,
          endValue: 0,
          property: "translateX",
          unit: "px",
        },
      ],
    },
  ];

  const parallaxData1 = [
    {
      start: props.start + props.height * 1.5,
      end: props.start + props.height * 2.5 + props.height / 4,

      properties: [
        {
          startValue: 1200,
          endValue: 0,
          property: "translateX",
          unit: "px",
        },
      ],
    },
  ];

  return (
    <div class="roadmap-container" ref={collebsRef}>
      <div class="roadmap-content">
        <div class="roadmap-copy">
          <div class="roadmap-head">
            <h3>
              NOVA <span>VARSITY JACKET</span>
            </h3>
          </div>
          <div class={animation ? "roadmap-body active" : "roadmap-body "}>
            <div class="roadmap-preview-outer">
              <div class="roadmap-preview" ref={jacketRef}>
                <video playsInline loop muted={true} autoPlay>
                  <source
                    src="https://cdn.cultandrain.com/SHUFFLE_white.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div
                className="roadmap-num roadmap-num1"
                parallaxData={parallaxData}
              >
                <div class="roadmap-num-inside">
                  <h4>5</h4>
                  <span>COLORWAYS</span>
                </div>
              </div>

              <div class="roadmap-num roadmap-num2">
                <div class="roadmap-num-inside">
                  <h4>1</h4>
                  <span>STYLE</span>
                </div>
              </div>
              <div class="roadmap-num roadmap-num3">
                <div class="roadmap-num-inside">
                  <h4>228</h4>
                  <span>NFTs AND PHYSICAL JACKETS</span>
                </div>
              </div>
              <div class="roadmap-num roadmap-num4">
                <div class="roadmap-num-inside">
                  <h4>912</h4>
                  <span>METAVERSE WEARABLES</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JacketComponent;
