import * as React from "react";
import Plx from "react-plx";

const Discover = (props) => {
  const discoverRef = React.useRef(null);

  let comingSoonData = [
    {
      start:
        props.start +
        (discoverRef.current ? discoverRef.current.clientHeight : 0) / 4,
      end:
        props.start +
        (discoverRef.current ? discoverRef.current.clientHeight : 0) +
        (discoverRef.current ? discoverRef.current.clientHeight : 0) / 4,
      properties: [
        {
          startValue: -50,
          endValue: -50,
          property: "translateX",
          unit: "%",
        },
      ],
    },
    {
      start:
        props.start +
        (discoverRef.current ? discoverRef.current.clientHeight : 0) / 4,
      end:
        props.start +
        (discoverRef.current ? discoverRef.current.clientHeight : 0) +
        (discoverRef.current ? discoverRef.current.clientHeight : 0) / 4,
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
    {
      start:
        props.start +
        (discoverRef.current ? discoverRef.current.clientHeight : 0) / 4,
      end:
        props.start +
        (discoverRef.current ? discoverRef.current.clientHeight : 0) +
        (discoverRef.current ? discoverRef.current.clientHeight : 0) / 4,
      properties: [
        {
          startValue: 0,
          endValue: -1,
          property: "scaleY",
        },
      ],
    },
    {
      start:
        props.start +
        (discoverRef.current ? discoverRef.current.clientHeight : 0) / 4,
      end:
        props.start +
        (discoverRef.current ? discoverRef.current.clientHeight : 0) +
        (discoverRef.current ? discoverRef.current.clientHeight : 0) / 4,
      properties: [
        {
          startValue: props.orientation == "portrait" ? 72 : 35,
          endValue: props.orientation == "portrait" ? 48 : 26.5,
          property: "top",
          unit: props.orientation == "portrait" ? "px" : "vh",
        },
      ],
    },
  ];

  React.useEffect(() => {
    props.total(discoverRef.current ? discoverRef.current.clientHeight : 0);
    return () => {};
  });

  return (
    <div ref={discoverRef} className="culturerain">
      <h2>DISCOVER OUR NFTS</h2>
      <h3 style={{top:(props.orientation == "portrait" ? '48' : '26.5')+(props.orientation == "portrait" ? "px" : "vh"),opacity:1,left:'auto',transform:'scaleY(-1)'}}>DISCOVER OUR NFTS</h3>
      <p>
        <a href="https://opensea.io/collection/cultandrain-drop-001">
          BUY ON OPENSEA
        </a>
      </p>
    </div>
  );
};

export default Discover;
