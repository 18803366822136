import * as React from "react";
import Plx from "react-plx";
import Shoe1 from "../images/2.0/shoe_white.png";

const isBrowser = typeof window !== "undefined";

// markup
const Shoes = (props) => {
  const shoesRef = React.useRef(null);
  const fireRowRef = React.useRef(null);

  const [hasStarted, setHasStarted] = React.useState(false);

  let start = props.start;

  let stages = [
    fireRowRef.current ? fireRowRef.current.clientHeight : 0,
    fireRowRef.current ? fireRowRef.current.clientHeight : 0,
    fireRowRef.current ? fireRowRef.current.clientHeight : 0,
    fireRowRef.current ? fireRowRef.current.clientHeight : 0,
    fireRowRef.current ? fireRowRef.current.clientHeight : 0
  ];

  let totalHeight = stages.reduce((a, b) => a + b);


  let shoeNameDataA = [
    {
      start: props.start + (props.height/2) + stages[0],
      end: props.start + (props.height/2) + (stages[0]*2),
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
    {
      start: props.start + (props.height/2) + stages[0],
      end: props.start + (props.height/2) + (stages[0]*2),
      properties: [
        {
          startValue: props.orientation == "portrait" ? -17 : 0,
          endValue: props.orientation == "portrait" ? -17 : -8,
          property: "right",
          unit: "vw",
        },
      ],
    },
  ];

  let shoeNameDataB = [
    {
      start: shoeNameDataA[0].end,
      end: shoeNameDataA[0].end + stages[4],
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
    {
      start: shoeNameDataA[0].end,
      end: shoeNameDataA[0].end + stages[4],
      properties: [
        {
          startValue: props.orientation == "portrait" ? 5 : 0,
          endValue: props.orientation == "portrait" ? 5 : -6,
          property: "left",
          unit: "vw",
        },
      ],
    },
  ];

  let shoeNameDataC = [
    {
      start: shoeNameDataB[0].end,
      end: shoeNameDataB[0].end + stages[4],
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
    {
      start: shoeNameDataB[0].end,
      end: shoeNameDataB[0].end + stages[4],
      properties: [
        {
          startValue: props.orientation == "portrait" ? -30 : 0,
          endValue: props.orientation == "portrait" ? -30 : -10,
          property: "right",
          unit: "vw",
        },
      ],
    },
  ];
  let shoeNameDataD = [
    {
      start: shoeNameDataC[0].end,
      end: shoeNameDataC[0].end + stages[4],
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
    {
      start: shoeNameDataC[0].end,
      end: shoeNameDataC[0].end + stages[4],
      properties: [
        {
          startValue: props.orientation == "portrait" ? 7 : 0,
          endValue: props.orientation == "portrait" ? 7 : -6,
          property: "left",
          unit: "vw",
        },
      ],
    },
  ];

  let shoeNameDataE = [
    {
      start: shoeNameDataD[0].end,
      end: shoeNameDataD[0].end + stages[4],
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
    {
      start: shoeNameDataD[0].end,
      end: shoeNameDataD[0].end + stages[4],
      properties: [
        {
          startValue: props.orientation == "portrait" ? -30 : 0,
          endValue: props.orientation == "portrait" ? -30 : -10,
          property: "right",
          unit: "vw",
        },
      ],
    },
  ];

  const updateScrollPosition = () => {
    if (!hasStarted && window.scrollY >= props.start) {
      setHasStarted(true);
    }
  };

  React.useEffect(() => {
    if (isBrowser) window.addEventListener("scroll", updateScrollPosition);
    props.total(shoesRef.current ? shoesRef.current.clientHeight : 0);
    return () => {
      if (isBrowser) window.removeEventListener("scroll", updateScrollPosition);
    };
  });

  return (
    <div
      ref={shoesRef}
      className="shoes homePage"
      style={{ minHeight: `${totalHeight}px` }}
    >
      <div className="shoes_copy ">
        <div className="fire">
          <div className="shoe_container" ref={fireRowRef}>
            <div className="shoe_box">
              <Plx
                animateWhenNotInViewport={true}
                className="shoe_name shoe_name_a"
                parallaxData={shoeNameDataA}
              >
                <div>
                  <h3>NOVA</h3>
                  <h4>05</h4>
                  <p>THE ONE</p>
                  <p style={{marginTop:'2px'}}>PHYGITAL JACKET</p>
                </div>
              </Plx>
            </div>
            <div className="shoea">
              <video playsInline loop muted autoPlay>
                <source
                  src="https://cdn.cultandrain.com/drop001/JCK_CW_5__BLACK.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
          <div className="shoe_container">
            <div className="shoe_box hide-desktop">
              <Plx
                animateWhenNotInViewport={true}
                className="shoe_name shoe_name_b"
                parallaxData={shoeNameDataB}
              >
                <div>
                  <h3>NOVA</h3>
                  <h4>04</h4>
                  <p>Web3 Community</p>
                  <p style={{marginTop:'2px'}}>PHYGITAL JACKET</p>
                </div>
              </Plx>
            </div>

            <div className="shoeb">
              <video playsInline loop muted autoPlay>
                <source
                  src="https://cdn.cultandrain.com/JCK_CW_4__BLACK.mp4"
                  type="video/mp4"
                />
              </video>
            </div>

            <div className="shoe_box hide-mobile">
              <Plx
                animateWhenNotInViewport={true}
                className="shoe_name shoe_name_b"
                parallaxData={shoeNameDataB}
              >
                <div>
                  <h3>NOVA</h3>
                  <h4>04</h4>
                  <p>Web3 Community</p>
                  <p style={{marginTop:'2px'}}>PHYGITAL JACKET</p>
                </div>
              </Plx>
            </div>
          </div>
          <div className="shoe_container">
            <div className="shoe_box">
              <Plx
                animateWhenNotInViewport={true}
                className="shoe_name shoe_name_c"
                parallaxData={shoeNameDataC}
              >
                <div>
                  <h3>NOVA</h3>
                  <h4>03</h4>
                  <p>METAVERSE WEARABLE</p>
                </div>
              </Plx>
            </div>
            <div className="shoec">
              <video playsInline loop muted autoPlay>
                <source
                  src="https://cdn.cultandrain.com/JCK_CW_3__BLACK.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
          <div className="shoe_container">
            <div className="shoe_box hide-desktop">
              <Plx
                animateWhenNotInViewport={true}
                className="shoe_name shoe_name_b "
                parallaxData={shoeNameDataD}
              >
                <div>

                  <h3>NOVA</h3>
                  <h4>02</h4>
                  <p>METAVERSE WEARABLE</p>
                </div>
              </Plx>
            </div>

            <div className="shoeb">
              <video playsInline loop muted autoPlay>
                <source
                  src="https://cdn.cultandrain.com/JCK_CW_2__BLACK.mp4"
                  type="video/mp4"
                />
              </video>
            </div>

            <div className="shoe_box hide-mobile">
              <Plx
                animateWhenNotInViewport={true}
                className="shoe_name shoe_name_b "
                parallaxData={shoeNameDataD}
              >
                <div>

                  <h3>NOVA</h3>
                  <h4>02</h4>
                  <p>METAVERSE WEARABLE</p>
                </div>
              </Plx>
            </div>
          </div>
          <div className="shoe_container">
            <div className="shoe_box">
              <Plx
                animateWhenNotInViewport={true}
                className="shoe_name shoe_name_c"
                parallaxData={shoeNameDataE}
              >
                <div>
                  <h3>NOVA</h3>
                  <h4>01</h4>
                  <p>METAVERSE WEARABLE</p>
                </div>
              </Plx>
            </div>
            <div className="shoec">
              <video playsInline loop muted autoPlay>
                <source
                  src="https://cdn.cultandrain.com/JCK_CW_1__BLACK.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shoes;
